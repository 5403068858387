import { default as React } from "react"
import {
  connectStateResults,
  Hits,
  Index,
  Pagination,
  PoweredBy,
} from "react-instantsearch-dom"
import A11Announcer from "./a11-announcer"
import SearchResultItem from "./search-result-item"
const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits
  return (
    <A11Announcer
      message={`Lista tem ${hitCount} ${
        hitCount === 1 ? "resultado" : "resultados"
      }.`}
    />
  )
})

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount />
    <Hits className="search-result-list" hitComponent={SearchResultItem} />
  </Index>
)
const SearchResultList = ({ indices }) => (
  <div>
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
    <Pagination
      showFirst={true}
      showPrevious={true}
      showNext={true}
      showLast={true}
      translations={{
        previous: "←",
        next: "→",
        first: "⇤",
        last: "⇥",
        page(currentRefinement) {
          return currentRefinement
        },
        ariaPrevious: "Página anterior",
        ariaNext: "Próxima página",
        ariaFirst: "Primeira página",
        ariaLast: "Última página",
        ariaPage(currentRefinement) {
          return `Página ${currentRefinement}`
        },
      }}
    />
    <PoweredBy
      translations={{
        searchBy: "Busca por",
      }}
    />
  </div>
)
export default SearchResultList
