import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"

const SearchBar = connectSearchBox(({ refine, currentRefinement }) => (
  <form autoComplete="off">
    <div className="search-bar">
      <label htmlFor="header-search">
        <span className="visually-hidden">Pesquisar posts do blog</span>
      </label>
      <input
        type="text"
        id="header-search"
        aria-label="Pesquisa"
        placeholder="Escreva aqui..."
        onChange={e => refine(e.target.value)}
        value={currentRefinement}
      />
    </div>
  </form>
))

export default SearchBar
