import { Link } from "gatsby"
import React from "react"
import { Highlight, Snippet } from "react-instantsearch-dom"
import Category from "./category"

const SearchResultItem = ({ hit }) => (
  <li className="search-result-item">
    <Link to={"/" + hit.slug} itemProp="url">
      <h2>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </h2>
    </Link>
    <Snippet attribute="excerpt" hit={hit} tagName="mark" />
    <br />
    <span className="footer">
      {hit.date} /{" "}
      {hit.categories
        .map(t => <Category key={t} catName={t} />)
        .reduce((prev, curr) => [prev, ", ", curr])}
    </span>
  </li>
)

export default SearchResultItem
