import algoliasearch from "algoliasearch/lite"
import { default as React, useMemo } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SearchBar from "../components/search-bar"
import SearchResultList from "../components/search-result-list"
import { InstantSearch } from "react-instantsearch-core"

const Pesquisa = ({ data, location }) => {
  const description = data.site.siteMetadata.description
  const logo = data.logo.childImageSharp.gatsbyImageData
  const pageTitle = "Pesquisa"
  const indices = [{ name: `Posts`, title: `Posts` }]
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )

  return (
    <Layout description={description} logo={logo}>
      <Seo
        title={pageTitle}
        description="Serviço de busca do blog, com ajuda do sistema de pesquisa do Google."
      />
      <div className="post-wrapper">
        <h1 itemProp="headline">{pageTitle}</h1>
        <div className="post-text">
          <InstantSearch
            searchClient={searchClient}
            indexName={indices[0].name}
          >
            <SearchBar />
            <SearchResultList indices={indices} />
          </InstantSearch>
        </div>
      </div>
    </Layout>
  )
}

export default Pesquisa

export const pageQuery = graphql`
  {
    logo: file(absolutePath: { regex: "/logo.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
